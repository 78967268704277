<!-- 二维码不通过预警详情 -->
<template>
  <section v-if="current?.businessType == 35">
    <a-descriptions
      title=""
      layout="vertical"
      bordered
      :column="2"
      size="small"
      class="descript"
      style="border-top: 1px solid #f0f0f0"
    >
      <a-descriptions-item label="盖印人">{{ current?.staffName || '-' }}</a-descriptions-item>
      <a-descriptions-item label="印章名称">{{ current?.sealName || '-' }}</a-descriptions-item>
      <a-descriptions-item label="流程主题">
        <a class="detail-link" @click.prevent="toDetail(current?.documentId)">{{ current?.fileName || '-' }}</a>
      </a-descriptions-item>
      <a-descriptions-item label="拍摄时间">{{ current?.warningTime || '-' }}</a-descriptions-item>
      <a-descriptions-item label="印章位置" :span="3">{{ current?.practicalAddress || '-' }}</a-descriptions-item>
      <a-descriptions-item label="二维码图片" :span="3">
        <section class="img-collection" v-if="current.fullFile">
          <div class="seal-imgs">
            <div class="image-box">
              <img :src="current.fullFile + '?uploadFile=88x88'" class="seal-img" v-viewer="printImgOptions" />
            </div>
          </div>
        </section>
        <span v-else>无</span>
      </a-descriptions-item>
    </a-descriptions>
  </section>
</template>
<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  current: {
    type: Object,
    default: () => ({})
  },
  printImgOptions: {
    type: Object
  },
  toDetail: {
    type: Function
  }
})
</script>
<style lang="less" scoped>
@import url('./shareStyle.less');
</style>
